<template>
  <div class="app">
    <AppHeader fixed>
      <SidebarToggler
        class="d-lg-none"
        display="md"
        mobile
      />
      <b-link
        class="navbar-brand"
        to="/dashboard"
      >
        <img
          class="navbar-brand-full"
          src="/img/brand/logo.svg"
          width="89"
          height="25"
          alt="CoreUI Logo"
        >
        <img
          class="navbar-brand-minimized"
          src="/img/brand/sygnet.svg"
          width="30"
          height="30"
          alt="CoreUI Logo"
        >
      </b-link>
      <SidebarToggler
        class="d-md-down-none"
        display="lg"
      />
      <b-navbar-nav class="ml-auto align-middle navbar">
        <div class="d-md-down-none mr-auto px-4" v-if="typeof $store.state.rating === 'number'">
          <b-nav-item class="d-md-down-none" to="/my-rating">
            <strong>Текущий рейтинг: <span :class="{'rating': true, 'rating-low': $store.state.rating <= 30, 'rating-mid': $store.state.rating >= 31 && $store.state.rating <= 49, 
            'rating-high': $store.state.rating >= 50}">{{ $store.state.rating }}</span></strong>
          </b-nav-item>
        </div>
        <b-nav-item>
          <DefaultHeaderDropdownNotif @totalCount="getTotalCount"/>
        </b-nav-item>
        <b-nav-item class="d-md-down-none">
          <DefaultHeaderDropdown :totalCount="totalCount"/>
        </b-nav-item>
      </b-navbar-nav>
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader class="d-flex justify-content-start align-items-center d-lg-none">
          <span class="user-settings" @click="hideSideBar">
            <router-link to="/user-settings" class="user-settings-icon">
                <i class="fa fa-cog" />
            </router-link>
          </span>
          <router-link to="/my-profile" class="username">
            <strong @click="hideSideBar">{{ $store.state.user.username.length > 20 ? `${$store.state.user.username.substring(0, 17)}...` : $store.state.user.username }}</strong>
          </router-link>
        </SidebarHeader>
        <div class="d-flex justify-content-center align-middle rating-sidebar d-lg-none">
          <span class="px-1">Текущий рейтинг: </span><span :class="{'rating-low': $store.state.rating <= 30, 'rating-mid': $store.state.rating >= 31 && $store.state.rating <= 49, 
          'rating-high': $store.state.rating >= 50}">{{ $store.state.rating }}</span>
        </div>
        <SidebarForm />
        <SidebarNav :nav-items="navItems"/>
        <SidebarFooter class="d-flex justify-content-center d-lg-none">
          <strong @click="$store.commit('logout')">Выйти</strong>
        </SidebarFooter>
        <SidebarMinimizer class="d-lg-none"/>
      </AppSidebar>
      <main class="main">
        <Breadcrumb :list="list" />
        <div class="container-fluid">
          <router-view />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import navCustomer from '../_navCustomer';
import navContractor from '../_navContractor';
import {Header as AppHeader, SidebarToggler, Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, SidebarNav, Aside as AppAside, AsideToggler, Footer as TheFooter, Breadcrumb} from '@coreui/vue';
import DefaultAside from './DefaultAside';
import DefaultHeaderDropdown from './DefaultHeaderDropdown';
import DefaultHeaderDropdownNotif from './DefaultHeaderDropdownNotif';
import {mapGetters} from 'vuex';

export default {
  name: 'DefaultContainer',
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    Breadcrumb,
    DefaultAside,
    DefaultHeaderDropdown,
    DefaultHeaderDropdownNotif,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
  },
  data() {
    return {
      totalCount: 0,
      visible: false
    };
  },
  computed: {
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter((route) => route.name || route.meta.label );
    },
    ...mapGetters({
      isCustomer: 'isCustomer',
      isContractor: 'isContractor',
    }),
    navItems() {
      if (this.isCustomer) {
        return navCustomer.items;
      } else if (this.isContractor) {
        return navContractor.items;
      }
    },
  },
  methods: {
    getTotalCount(data) {
      this.totalCount = data.totalCount;
    },
    hideSideBar() {
      document.body.classList.remove('sidebar-show');
    }
  }
};
</script>

<style lang="scss">
.user-settings {
  padding-right: 13px;
  font-size: 20px;

  &-icon {
    text-decoration: none !important;
    color: unset !important;
  }
}
</style>
<template>
  <AppHeaderDropdown
    right
    no-caret
  >
    <template slot="header">
      <b-navbar-nav :class="{'px-1': totalCount < 10000, 'px-3': totalCount >= 1000}">
        <b-nav-text>{{ $store.state.user.username }} <span v-if="$store.state.user.company">({{ $store.state.user.company }})</span></b-nav-text>
      </b-navbar-nav>
    </template>
    <template
      slot="dropdown"
      tag="div"
    >
      <!-- <div class="dropdown-header text-center"><strong>Мой профиль</strong></div> -->
      <b-dropdown-item to="/my-profile">
        <i class="fa fa-user-o" />Мой профиль
      </b-dropdown-item>
      <b-dropdown-item to="/user-settings">
        <i class="fa fa-cog" />Настройки пользователя
      </b-dropdown-item>
      <!-- <b-dropdown-item to="/profile/requisites"><i class="fa fa-file-text-o" />Реквизиты</b-dropdown-item> -->
      <!--<b-dropdown-item to="/profile/reliability-rating"><i class="fa fa-star-o" />Рейтинг надежности</b-dropdown-item>-->
      <!--<b-dropdown-item to="/profile/settings"><i class="fa fa-gear" />Настройки</b-dropdown-item>-->
      <!--<b-dropdown-item to="/profile/notifications"><i class="fa fa-bell-o" />Уведомления<span class="badge badge-danger">5</span></b-dropdown-item>-->
      <div
        role="separator"
        class="dropdown-divider"
      />
      <b-dropdown-item @click="$store.commit('logout')">
        <i class="fa fa-lock" />Выход
      </b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import {HeaderDropdown as AppHeaderDropdown} from '@coreui/vue';
export default {
  name: 'DefaultHeaderDropdown',
  props: {
    totalCount: Number
  },
  components: {
    AppHeaderDropdown,
  }
};
</script>
<style>
.dropdown-menu {
  min-width: 220px;
}
</style>
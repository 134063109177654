export default {
  items: [
    {
      name: 'Контактные реквизиты',
      url: '/contact-requisites',
      icon: 'fa fa-address-card',
    },
    {
      name: 'Пользователи',
      url: '/customer-users',
      icon: 'fa fa-user-plus',
    },
    {
      name: 'Перевозчики',
      url: '/contractor-list',
      icon: 'fa fa-users',
      children: [
        {
          name: 'Перевозчики',
          url: '/contractor-list',
          icon: 'fa fa-users',
        },
        {
          name: 'Списки перевозчиков',
          url: '/contractor-lists-list',
          icon: 'fa fa-list-alt',
        },
        {
          name: 'Доверенности перевозчиков',
          url: '/attorneys/customer',
          icon: 'fa fa-file-text',
        },
        {
          name: 'Водители перевозчиков',
          url: '/drivers/customer',
          icon: 'fa fa-drivers-license',
        },
        {
          name: 'Черновики водителей',
          url: '/driver-drafts',
          icon: 'fa fa-drivers-license-o',
        },
        {
          name: 'Транспортные средства перевозчиков',
          url: '/transport-vehicles/customer',
          icon: 'fa fa-car',
        },
      ],
    },
    {
      name: 'Рейсы',
      url: '/flights/customer',
      icon: 'fa fa-truck',
    },
    {
      name: 'Аукционы',
      url: '/auctions/customer',
      icon: 'fa fa-gavel',
    },
    {
      name: 'Тендеры',
      url: '/tenders/customer',
      icon: 'fa fa-comments',
    },
    {
      name: 'Габариты ТС',
      url: '/dimensions/customer',
      icon: 'fa fa-cubes',
      children: [
        {
          name: 'Габариты',
          url: '/dimensions/customer',
          icon: 'fa fa-cubes',
        },
        {
          name: 'Связи габаритов',
          url: '/route-way-dimensions/customer',
          icon: 'fa fa-cubes',
        },
      ],
    },
    {
      name: 'Типы перевозок',
      url: '/transportation-names',
      icon: 'fa fa-exchange',
    },
    {
      name: 'Менеджеры СТЛ',
      url: '/customer/stl',
      icon: 'fa fa-share',
    },
    {
      name: 'Рейтинг',
      url: '/customer/rating/settings',
      icon: 'fa fa-line-chart',
      children: [
        {
          name: 'Топ-10',
          url: '/customer/rating/top',
          icon: 'fa fa-bar-chart',
        },
        {
          name: 'Настройки рейтинга',
          url: '/customer/rating/settings',
          icon: 'fa fa-cog',
        },
        {
          name: 'Апелляции',
          url: '/customer/rating/appeals',
          icon: 'fa fa-envelope-open-o',
        },
      ],
    },
    {
      name: 'Отчеты',
      url: '/reports',
      icon: 'fa fa-pie-chart',
      children: [
        {
          name: 'Отчет по аукционам',
          url: '/reports/auctions',
          icon: 'fa fa-gavel',
        },
        {
          name: 'Отчет по рейсам',
          url: '/reports/routes',
          icon: 'fa fa-truck',
        },
        {
          name: 'Отчет по тендерам',
          url: '/reports/tenders',
          icon: 'fa fa-comments',
        },
      ],
    },
    {
      name: 'Планы по аукционам',
      url: '/auction-plan',
      icon: 'fa fa-pie-chart',
    },
    {
      name: 'Видеоуроки',
      url: '/customers/video-lessons',
      icon: 'fa fa-play',
    },
    {
      name: 'Настройки примеров файлов',
      url: '/example-documents-configuration',
      icon: 'fa fa-cog',
    },
    {
      name: 'Руководство пользователя',
      url: '/customer-manual',
      icon: 'fa fa-info-circle',
    },
  ],
};

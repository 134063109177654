<template>
  <AppHeaderDropdown
    right
    no-caret
  >
    <template slot="header">
      <div :class="{'badge-wrapper': true, 'badge-max-total': totalCount >= 100000}">
        <i
          ref="notificationButton"
          class="icon-bell bell-fix"
        /><b-badge
          v-show="totalCount"
          pill
          variant="danger"
          class="badge-fix"
        >
          {{ totalCount >= 100000 ? '100 000+' : numberRUFormat(totalCount) }}
        </b-badge>
      </div>
    </template>
    <template slot="dropdown">
      <div
        ref="notificationDropdown"
        class="notifications"
      >
        <b-dropdown-header
          tag="div"
          class="notifications__header text-center"
        >
          <router-link to="/notifications">
            <strong>Все уведомления</strong>
          </router-link>
          <router-link to="/user-settings" class="notifications__header-settings-link">
            <span class="fa fa-cog fa-lg text-info"></span>
          </router-link>
        </b-dropdown-header>
        <div
          v-for="(notification, index) in data"
          v-show="!notification.read"
          :key="notification.id"
          class="notification"
          :class="notification.class"
        >
          <p class="notification__title">
            <router-link
              v-if="notification.link"
              tag="a"
              class="notification__link"
              :to="notification.link"
            >
              {{ notification.title }}
            </router-link>
            <span v-else>{{ notification.title }}</span>
          </p>
          <a
            href="#"
            class="notification__show-desc-button"
            :class="{'opened' : notification.full}"
            @click="notification.full = !notification.full"
          />
          <div v-if="notification.full">
            <ul v-if="notification.actionList && notification.actionList.length > 0">
              <li
                v-for="item in notification.actionList"
                class="notification__desc text-secondary"
              >
                {{ item }}
              </li>
            </ul>
            <p
              v-else
              class="notification__desc text-secondary"
            >
              {{ notification.body }}
            </p>
          </div>
          <a
            v-b-tooltip.hover.bottom="'Отметить как «Прочитанное»'"
            href="#"
            class="notification__check-button"
            :class="{'checked' : notification.read}"
            @click="notificationsReadNotification(notification, index)"
          />
        </div>
        <a
          href="#"
          class="notifications__clean-button"
          type="button"
          @click="notificationsReadAllNotifications"
        >Очистить</a>
      </div>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import {HeaderDropdown as AppHeaderDropdown} from '@coreui/vue';
import notifications from '../components/mixins/notifications';
import {notificationsRead, notificationsReadNotification, notificationsReadAllNotifications} from '../services/api';
import {numberToRUFormat} from '../components/mixins/helpers';

const socket = new WebSocket(process.env.VUE_APP_WEBSOCKET_NOTIFICATIONS_API_URL);

socket.onopen = () => {
  socket.send(JSON.stringify({
    event: 'authorize',
    data: {
      jwt: localStorage.token,
    },
  }));
};

export default {
  name: 'DefaultHeaderDropdownNotif',
  components: {
    AppHeaderDropdown,
  },
  mixins: [notifications],
  data: () => {
    return {
      data: [],
      totalCount: null,
      loading: false,
    };
  },
  sockets: {
    connect: function() {
      console.log('socket connected');
    },
    customEmit: function(data) {
      console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)');
    },
  },
  created() {
    socket.onmessage = (message) => {
      try {
        const event = JSON.parse(message.data);
        if (event.id) {
          this.showInfo('У Вас новое уведомление');
          event.full = false;

          switch (event.action) {
          case 'success':
            event.class = 'notification_add';
            break;
          case 'add':
            event.class = 'notification_add';
            break;
          case 'danger':
            event.class = 'notification_del';
            break;
          case 'declined':
            event.class = 'notification_del';
            break;
          case 'update':
            event.class = 'notification_info';
            break;
          default:
            event.class = 'notification_info';
            break;
          }

          if (/contactRequisite/.test(event.type)) {
            event.class = event.class + ' ' + 'notification_requisite';
          } else if (/route/.test(event.type)) {
            event.actionList = [];
            event.class = event.class + ' ' + 'notification_route';
            if (event.additionalData.contractor) {
              event.actionList.push('Рейс добавлен в Ваш список рейсов и доступен для управления');
            }
            if (event.additionalData.planDateOfFirstPointArrive || event.additionalData.planDateOfFirstPointLoading) {
              event.actionList.push('Планируемые даты изменились');
            }
            if (event.additionalData.routeOwnerId) {
              event.actionList.push('Ответственный за рейс изменился');
            }
            if (event.additionalData.boostFlag) {
              event.actionList.push('Флаг «Ускоренный» изменился');
            }
            if (event.additionalData.driverOne || event.additionalData.driverTwo) {
              event.actionList.push('Водители были изменены');
            }
            if (event.additionalData.transport) {
              event.actionList.push('Автомобиль изменился');
            }
          } else if (/route/.test(event.type)) {
            event.class = event.class + ' ' + 'notification_route';
          } else if (/driver/.test(event.type)) {
            event.class = event.class + ' ' + 'notification_driver';
          } else if (/vehicle/.test(event.type)) {
            event.class = event.class + ' ' + 'notification_vehicle';
          } else if (/auction/.test(event.type)) {
            event.class = event.class + ' ' + 'notification_auction';
          }
          this.data.unshift(event);
        }
      } catch (e) {
        console.log(e);
      }
    };
    socket.onclose = (event) => {
      console.log('onclose');
      console.log(event);
    };
    socket.onerror = (error) => {
      console.log('onerror');
      console.log('Ошибка ' + error.message);
    };
  },
  mounted() {
    this.notificationsRead();
  },
  methods: {
    async notificationsRead() {
      this.loading = true;
      const response = await notificationsRead(this.$store.state.user.userId, {limit: 100, read: false});
      if (response && response.status === 200) {
        this.totalCount = response.data.totalCount;
        this.emitTotalCount(response.data.totalCount);
        this.data = response.data.items.map((item) => {
          item.full = false;

          switch (item.action) {
          case 'success':
            item.class = 'notification_add';
            break;
          case 'add':
            item.class = 'notification_add';
            break;
          case 'danger':
            item.class = 'notification_del';
            break;
          case 'declined':
            item.class = 'notification_del';
            break;
          case 'update':
            item.class = 'notification_info';
            break;
          default:
            item.class = 'notification_info';
            break;
          }

          if (/contactRequisite/.test(item.type)) {
            item.class = item.class + ' ' + 'notification_requisite';
          } else if (/route/.test(item.type)) {
            item.actionList = [];
            item.class = item.class + ' ' + 'notification_route';
            if (item.additionalData?.contractor) {
              item.actionList.push('Рейс добавлен в Ваш список рейсов и доступен для управления');
            }
            if (item.additionalData?.planDateOfFirstPointArrive || item.additionalData?.planDateOfFirstPointLoading) {
              item.actionList.push('Планируемые даты изменились');
            }
            if (item.additionalData?.routeOwnerId) {
              item.actionList.push('Ответственный за рейс изменился');
            }
            if (item.additionalData?.boostFlag) {
              item.actionList.push('Флаг «Ускоренный» изменился');
            }
            if (item.additionalData?.driverOne || item.additionalData?.driverTwo) {
              item.actionList.push('Водители были изменены');
            }
            if (item.additionalData?.transport) {
              item.actionList.push('Автомобиль изменился');
            }
          } else if (/route/.test(item.type)) {
            item.class = item.class + ' ' + 'notification_route';
          } else if (/driver/.test(item.type)) {
            item.class = item.class + ' ' + 'notification_driver';
          } else if (/vehicle/.test(item.type)) {
            item.class = item.class + ' ' + 'notification_vehicle';
          } else if (/auction/.test(item.type) || /bid/.test(item.type)) {
            item.class = item.class + ' ' + 'notification_auction';
          }
          return item;
        });
      }
      this.loading = false;
    },
    async notificationsReadNotification(notification, index) {
      notification.read = true;
      this.loading = true;
      const response = await notificationsReadNotification(this.$store.state.user.userId, notification.id);
      if (response && response.status === 200) {
        this.data.splice(index, 1);
      }
      this.loading = false;
    },
    async notificationsReadAllNotifications() {
      const notifications = this.data.map((item) => {
        return item.id;
      });
      this.loading = true;
      const response = await notificationsReadAllNotifications(this.$store.state.user.userId);
      if (response && response.status === 200) {
        this.data = [];
        this.totalCount = null;
      }
      this.loading = false;
    },
    openDropdown() {
      if (!this.$refs.notificationButton.parentElement.parentElement.classList.contains('show')) {
        this.$refs.notificationButton.parentElement.click();
      }
    },
    emitTotalCount(totalCount) {
      this.$emit('totalCount', {
        totalCount: totalCount
      });
    },
    numberRUFormat(value) {
      return numberToRUFormat(value);
    }
  },
};
</script>

<style lang="scss">
  .badge-wrapper {
    display: flex;
    justify-content: space-between;

    .badge-fix {
      display: block;
      margin-left: 17px !important;
    }
  }

  .badge-max-total {
    padding-right: 45px;
  }

  .notifications {
    overflow: auto;
    max-width: 250px;
    max-height: 50vh;
    margin: 0;
    padding: 34px 0;

    .dropdown-item {
      padding-left: 10px;
      padding-right: 10px;

      &:active {
        color: #181b1e;
        background-color: #f0f3f5;
      }
    }

    &__header {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 1;

      &-settings-link {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);

        &:hover {

          .fa {
            color: #187da0 !important;
          }
        }

        .fa {
          transition: color .33s;
        }
      }
    }
    &__clean-button {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      display: block;
      width: 100%;
      padding: 8px 20px;
      margin-bottom: 0;
      background: #e4e7ea;
      border: 0;
      font-weight: bold;
      font-size: 0.76563rem;
      text-align: center;
      color: #73818f;
      white-space: nowrap;

      &:focus,
      &:hover {
        color: #73818f;
        text-decoration: none;
      }
    }
  }

  .notification {
    position: relative;
    display: block;
    max-width: 100%;
    width: 250px;
    padding: 10px;
    border-bottom: 1px solid #c8ced3;
    transition: opacity .5s;
    cursor: default;

    &:last-child {
      border-bottom: 0;
    }

    &:after {
      position: absolute;
      top: 10px;
      left: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 24px;
      height: 21px;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 16px;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &_auction {
      &:after {
        content: "\F0E3";
      }
    }
    &_driver {
      &:after {
        content: "\F007";
      }
    }
    &_vehicle {
      &:after {
        content: "\F1B9";
      }
    }
    &_requisite {
      &:after {
        content: "\F2BB";
      }
    }
    &_route {
      &:after {
        content: "\F0D1";
      }
    }

    &_add {
      .notification__title,
      .notification__link,
      &:after {
        color: #4dbd74;
      }
    }
    &_del {
      .notification__title,
      .notification__link,
      &:after {
        color: #f86c6b;
      }
    }
    &_info {
      .notification__title,
      .notification__link,
      &:after {
        color: #63c2de;
      }
    }

    &__title {
      margin-bottom: 0;
      padding-left: 24px;
      padding-right: 24px;
      white-space: normal;
    }
    &__link {
      word-break: break-word;
    }
    &__show-desc-button {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 10px;
      padding: 0;
      background-color: transparent;
      border: 0;
      font: normal normal normal 10px/1 FontAwesome;
      font-size: 10px;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      &:after {
        content: "\F0D7";
      }

      &:focus {
        outline: 0;
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
      }

      &.opened {
        color: #c8ced3;

        &:after {
          content: "\F0D8";
        }
      }
    }
    &__desc {
      margin-bottom: 0;
      white-space: normal;
    }
    &__check-button {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 24px;
      height: 21px;
      padding: 0;
      background-color: transparent;
      border: 0;
      font: normal normal normal 10px/1 FontAwesome;
      font-size: 16px;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      cursor: pointer;
      transition: color .33s;

      &:before {
        content: "\F096";
      }

      &:focus {
        outline: 0;
        text-decoration: none;
      }
      
      &:hover {
        color: #4dbd74;
        text-decoration: none;
      }

      &.checked {
        color: #4dbd74;

        &:before {
          content: "\F046";
        }
      }
    }
  }
</style>

export default {
  items: [
    {
      name: 'Профиль компании',
      url: '/profile',
      icon: 'fa fa-bank',
    },
    {
      name: 'Мои ресурсы',
      url: '/attorneys',
      icon: 'fa fa-align-justify',
      children: [
        {
          name: 'Доверенности',
          url: '/attorneys',
          icon: 'fa fa-file-text',
        },
        {
          name: 'Водители',
          url: '/drivers',
          icon: 'fa fa-drivers-license',
        },
        {
          name: 'Транспортные средства',
          url: '/transport-vehicles',
          icon: 'fa fa-car',
        },
        {
          name: 'Контейнеры',
          url: '/containers',
          icon: 'fa fa-suitcase',
        },
      ],
    },
    {
      name: 'Мои пользователи',
      url: '/contractor-users',
      icon: 'fa fa-user-plus',
    },
    {
      name: 'Рейсы',
      url: '/flights/contractor',
      icon: 'fa fa-truck',
    },
    {
      name: 'Аукционы',
      url: '/auctions/contractor',
      icon: 'fa fa-gavel',
    },
    {
      name: 'Тендеры',
      url: '/tenders/contractor',
      icon: 'fa fa-comments',
    },
    {
      name: 'Рейтинг',
      url: '/my-rating',
      icon: 'icon-chart',
    },
    {
      name: 'Видеоуроки',
      url: '/contractors/video-lessons',
      icon: 'fa fa-play',
    },
    {
      name: 'Помощь',
      url: '/help',
      icon: 'fa fa-life-ring',
    },
    {
      name: 'Руководство пользователя',
      url: '/contractor-manual',
      icon: 'fa fa-info-circle',
    },
  ],
};
